import {Box, Grid, Typography, Divider, Container} from '@mui/material';

const Descripcion = () =>{
    return(
        <Box sx={{marginTop:'2rem', marginBottom:'3rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Sorteo <span style={{color:'#00B8DF'}}>neowork</span>: Viaje a Cancún
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginTop:'2rem'}}>
                <Container>
                    <Box sx={{paddingLeft:'1rem', paddingRight:'1rem'}}>
                        <Typography gutterBottom>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum facilis atque inventore autem unde, eaque illo quas necessitatibus labore accusantium earum quasi suscipit laudantium dolorem pariatur eveniet aliquam quibusdam doloremque!
                        </Typography>
                        <Typography gutterBottom>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum facilis atque inventore autem unde, eaque illo quas necessitatibus labore accusantium earum quasi suscipit laudantium dolorem pariatur eveniet aliquam quibusdam doloremque!
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default Descripcion;