import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';


function CambioContrasenia() {
    return(
        <>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
                <EnhancedEncryptionIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
            Cambiar Contraseña
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirmar contraseña"
                type="password"
                id="password2"
            />
            <Button
                href='/#/admin'
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                    backgroundColor: '#0494b3'
                },}}
            >
                Cambiar
            </Button>
            </Box>
        </Box>
        </>
    )
}

export default CambioContrasenia;

