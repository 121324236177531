import UserLoginComponent from "../../modules/loginUser";



function UserLogin() {
    return(
        <>
        <UserLoginComponent/>
        </>
    )
}

export default UserLogin;