import UserPanelComponent from "../../modules/panel";


function UserPanel() {
    return(
        <>
        <UserPanelComponent/>
        </>
    )
}

export default UserPanel;