import * as React from 'react';
import {Tabs, Tab, Typography, Box, Grid, Divider, Container} from '@mui/material'
import PropTypes from 'prop-types';
import CardsActivos from './activos';
import CardsFinalizados from './final';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

  

const ContainerPanel = () =>{
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
    <Box  sx={{marginTop:'2rem'}}>
        <Grid container spacing={3}>
            <Grid item sm={1} xs={1}></Grid>
            <Grid item sm={4} xs={8}>
                <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                    Panel de <span style={{color:'#00B8DF'}}>participación</span>
                </Typography>
            </Grid>
        </Grid>
    </Box>
    <Container>
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
            '& .MuiTab-textColorPrimary': {
            color: '#00B8DF',  
            },
            '& .MuiTabs-indicator': {
            backgroundColor: '#00B8DF', 
            },
        }}>
            <Tab label="Activos" {...a11yProps(0)} />
            <Tab label="Finalzados" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
            <CardsActivos/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
            <CardsFinalizados/>
        </CustomTabPanel>
        </Box>
    </Container>
    <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
    </>
  );
}

export default ContainerPanel;