import SorteoOptions from "../../modules/tablero_sorteo";



function Sorteo() {
    return(
        <SorteoOptions/>
    )
}

export default Sorteo;