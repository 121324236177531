import {Typography, Dialog, DialogContent, DialogTitle, IconButton, Button, DialogActions} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';


const ModalSocio = (props) =>{

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
        <Typography variant="caption" display="block" sx={{textAlign:'center', fontWeight:'500'}} gutterBottom>
            ¿Eres socio Neowork? <span onClick={()=>handleClickOpen()} style={{fontWeight:'550', cursor:'pointer'}}>Conoce tus beneficios*</span>
        </Typography>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight:'600', width:'90%' }} id="customized-dialog-title">
                Beneficios de ser socio Neowork
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Todos los sorteos de Neowork tienen promociones especiales para sus asociados. En el caso de este sorteo en particular, los socios de Neowork tienen acceso a una promoción de <span style={{fontWeight:'550'}}>{props.promocion}</span> en boletos. Además de las oportunidades y promociones que tienen dentro del sistema principal de Neowork.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button component='a' target="_blank" href='https://neoworkglobal.com/sistema/register' sx={{color:'#00B8DF'}}>
                    Volverse socio
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default ModalSocio;