import Banner from "../tablero_sorteo/components/banner";
import Descripcion from "../tablero_sorteo/components/sorteo_descript";
import Ganador from "./components/winner";


const ResumenOptions = ()=>{
    return(
        <>
        <Banner/>
        <Descripcion/>
        <Ganador/>
        </>
    )
}

export default ResumenOptions;