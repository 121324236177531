import React, { useEffect } from 'react';
import { Route, HashRouter as Router, Routes, useLocation } from 'react-router-dom';
import Footer from './modules/footer';
import Menu from './modules/head_menu';
import Home from './pages/home';
import Ayuda from './pages/ayuda';
import Sorteo from './pages/sorteo';
import Resumen from './pages/resumen';
import Contacto from './pages/contacto';
import Login from './pages/login';
import AdminComponent from './pages/admin';
import SorteoForm from './pages/nuevoSorteo';
import CambioContrasenia from './modules/contrasenia';
import UserLogin from './pages/loginUser';
import Register from './pages/registro';
import UserPanel from './pages/panelUser';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Menu/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/ayuda" element={<Ayuda/>} />
        <Route path='/sorteo' element={<Sorteo/>}/>
        <Route path='/resumen' element={<Resumen/>}/>
        <Route path='/contacto' element={<Contacto/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/admin' element={<AdminComponent/>}/>
        <Route path='/formsorteo' element={<SorteoForm/>}/>
        <Route path='/contrasenia' element={<CambioContrasenia/>}/>
        <Route path='/userlogin' element={<UserLogin/>}/>
        <Route path='/registro' element={<Register/>}/>
        <Route path='/panel' element={<UserPanel/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
