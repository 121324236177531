import Typography from '@mui/material/Typography';
import {
  differenceInDays as dateFnsDifferenceInDays,
  differenceInHours as dateFnsDifferenceInHours,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  differenceInSeconds as dateFnsDifferenceInSeconds
} from 'date-fns';
import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const fechaFormateada = new Intl.DateTimeFormat('es-ES', options).format(targetDate);
  const calculateTimeUntil = (targetDate) => {
    const currentDate = new Date();

    const differenceInSeconds = dateFnsDifferenceInSeconds(targetDate, currentDate);
    const days = dateFnsDifferenceInDays(targetDate, currentDate);
    const hours = dateFnsDifferenceInHours(targetDate, currentDate) % 24;
    const minutes = dateFnsDifferenceInMinutes(targetDate, currentDate) % 60;
    const seconds = differenceInSeconds % 60;

    return {
      days: days >= 0 ? days : 0,
      hours: hours >= 0 ? hours : 0,
      minutes: minutes >= 0 ? minutes : 0,
      seconds: seconds >= 0 ? seconds : 0,
    };
  };

  const [time, setTime] = useState(calculateTimeUntil(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(calculateTimeUntil(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <Typography gutterBottom sx={{textAlign:'center', marginTop:'1rem'}}>
    Este sorteo se llevará a cabo conforme a la Lotería Nacional, que será celebrado el {fechaFormateada.toString()} <br></br> Faltan: 
      {time.days > 0 && (
        <span style={{color:'#00B8DF'}}>
            &nbsp;
          {time.days} día{time.days !== 1 ? 's' : ''}{' '}
        </span>
      )}
      {time.hours > 0 && (
        <span style={{color:'#00B8DF'}}>
          {time.hours} hora{time.hours !== 1 ? 's' : ''}{' '}
        </span>
      )}
      {time.minutes > 0 && (
        <span style={{color:'#00B8DF'}}>
          {time.minutes} minuto{time.minutes !== 1 ? 's' : ''}{' '}
        </span>
      )}
      {time.seconds > 0 && (
        <span style={{color:'#00B8DF'}}>
          {time.seconds} segundo{time.seconds !== 1 ? 's' : ''}
        </span>
      )}
      {time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0 && '¡Tiempo agotado!'}
    </Typography>
  );
};

export default CountdownTimer;