import { Avatar, Box, Button, CssBaseline, Grid, Paper, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';

const defaultTheme = createTheme();

const UserRegisterComponent = () => {

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={2} md={4} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding:'1rem'
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
            Registro
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 1 }}>
                <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Nombre"
                type="text"
                id="name"
                autoFocus
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="last-name"
                label="Apellidos"
                type="text"
                id="last"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Correo"
                type="email"
                id="email"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="cel"
                label="Celular"
                type="tel"
                id="cel"
            />
            <TextField
                margin="normal"
                fullWidth
                name="id"
                label="ID"
                type="number"
                id="id"
                helperText='Si es socio de Neowork, ingrese su ID, debe coincidir con su correo registrado previamente'
            />
            <Button
                onClick={()=>{localStorage.setItem('user', 'true'); window.location.href='/'}}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                    backgroundColor: '#0494b3'
                },}}
            >
                Registrar
            </Button>
            </Box>
        </Box>
        </Grid>
        <Grid item xs={false} sm={2} md={4} />
        <CssBaseline />
      </Grid>
    </ThemeProvider>
  );
}

export default UserRegisterComponent;