import { Box } from "@mui/material";
import SliderImages from "./components/slider";
import Sorteos from "./components/sorteos";
import SorteosFinalizados from "./components/sorteosfinalizados";


function TableroInicio() {
    return(
        <>
        <Box sx={{overflowX:'hidden'}}>
            <SliderImages/>
            <Sorteos/>
            <SorteosFinalizados/>
        </Box>
        </>
    )
}

export default TableroInicio;