import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, Box, Button, Container, Divider, Grid, TextField, Typography, Link} from '@mui/material';
import LOGO from '../../head_menu/logo-main.png'

const FormContac = () =>{

    
    return(
        <>
        <Box sx={{marginTop:'2rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Compártenos tu <span style={{color:'#00B8DF'}}>opinión</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Container>
                <Box>
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item md={6} sm={6} xs={12} >
                        <>
                            <Box
                                sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
                                    <CommentIcon/>
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                Añade un comentario
                                </Typography>
                                <Box component="form" noValidate  sx={{ mt: 1 }}>
                                    <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="email"
                                    label="Correo electrónico"
                                    type="email"
                                    id="email"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="comentario"
                                    label="Agrega un comentario"
                                    multiline
                                    rows={5}
                                    type="text"
                                    id="comentario"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                                        backgroundColor: '#0494b3'
                                    },}}
                                >
                                    Enviar
                                    <SendIcon sx={{marginLeft:'0.5rem'}} />
                                </Button>
                                </Box>
                            </Box>
                            </>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} container alignItems="center">
                            <Box
                                sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection:'column',
                                }}
                            >
                                <Typography variant='h4' sx={{ fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'550', textAlign:'center', marginBottom:'1rem'}}>
                                    Visita nuestro sitio oficial: <a href='https://neoworkglobal.com/' target='_blank' style={{color:'#00B8DF', cursor:'pointer'}}>Aqui</a>
                                </Typography>
                                <Link component={'a'} href='https://neoworkglobal.com/' target='_blank'>
                                    <img src={LOGO} style={{width:'100%'}}></img>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
        </>
    )
}

export default FormContac;