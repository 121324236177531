import { Box, Divider, Grid, Typography } from '@mui/material';

const Ganador = () =>{
    return(
        <Box sx={{marginTop:'2rem', marginBottom:'3rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Número ganador:  <span style={{color:'#00B8DF'}}>0304</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Ganador;