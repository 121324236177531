import { Avatar, Box, Button, CssBaseline, Grid, Paper, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import IMAGE from '../tablero_inicio/components/promo1.png'

const defaultTheme = createTheme();

const UserLoginComponent = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${IMAGE})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding:'1rem'
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
            </Avatar>
            <Typography component="h1" variant="h5">
            Iniciar Sesión
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 1 }}>
                <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Correo"
                type="email"
                id="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
            />
            <Button
                fullWidth
                variant="contained"
                onClick={()=>{localStorage.setItem('user', 'true'); window.location.href='/'}}
                sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                    backgroundColor: '#0494b3'
                },}}
            >
                Iniciar
            </Button>
            <Typography variant="caption" display="block" sx={{textAlign:'center', fontWeight:'500'}} gutterBottom>
                ¿Aún no tienes cuenta? <span onClick={()=>{window.location.href='/#/registro'}} style={{fontWeight:'550', cursor:'pointer', color:'#00B8DF'}}>Crear un nuevo usuario*</span>
            </Typography>
            </Box>
        </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default UserLoginComponent;