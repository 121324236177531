import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import LOGO_LETRA from './logo-main.png';
import LOGO from './logo.png'
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover'
import Avatar from '@mui/material/Avatar'
import LogoutIcon from '@mui/icons-material/Logout';
import GradeIcon from '@mui/icons-material/Grade';


const drawerWidth = 240;
const navItems = [
  {
    name: 'Sorteos',
    url: '/'
  },
  {
    name: 'Ayuda',
    url: '/#/ayuda'
  },
  {
    name:'Contacto',
    url:'/#/contacto'
  },
  {
    name: 'Nosotros',
    url: 'https://neoworkglobal.com/'} ];

function Menu(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
    
    <img src={LOGO} height='100px' style={{marginTop:'2rem'}} alt='Logo Neowork' />
    <Typography variant="h6" sx={{ my: 2, color: '#87877E' }}>
        NEOWORK
    </Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton href={item.url} sx={{ textAlign: 'center', color: '#87877E'}}>
              <ListItemText  primary={item.name} sx={{fontFamily:'BebasNeue', fontSize:'1rem' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{backgroundColor:'white'}} />
      {localStorage.getItem('user') !== null? 
        <>
        <Button size='large' href='/#/panel'  sx={{color:'#00B8DF', marginBottom:'0.5rem', marginTop:'0.5rem'}} > <GradeIcon sx={{marginRight:'0.3rem'}} /> Mis Sorteos</Button>
        <Button size='large' onClick={()=>{localStorage.removeItem('user');}} color='error' sx={{marginTop:'0.5'}}> <LogoutIcon sx={{marginRight:'0.3rem'}} /> Cerrar Sesión</Button>
        </>
        :
        <Button sx={{color:'#00B8DF', marginTop:'0.5rem'}} href='/#/userlogin'  >Iniciar Sesión</Button>
      }
    </Box>
  );

  function Remove() {
    localStorage.removeItem('user');
    window.location.reload()
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: 'column'  }}>
      <nav >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor:'#fffff' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <AppBar sx={{backgroundColor:'white'}} component="nav">
        <Toolbar style={{marginBottom:{sm:'1rem', xs:'0.8rem'}, marginTop:'1rem',  textAlign: {xs: 'center'}}}>
          <Box sx={{ flex: '0 0 10%', display: { sm: 'none' } }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              size='large'
              onClick={handleDrawerToggle}
              sx={{ mr:'1rem', ml:'0.5rem', color:'#87877E' }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
            <Box sx={{ display: { sm: 'block', xs: 'none' }, marginRight: '1rem' }}>
              <Link href='/'>
               <img src={LOGO_LETRA} width="180px" alt="Neowork letras logo" />
              </Link>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: { sm: 'none', xs: 'flex' },
                marginRight: '1rem',
                justifyContent: 'center',
              }}
            >
              <Link href='/'>
               <img src={LOGO_LETRA} width="180px" alt="Neowork letras logo" />
              </Link>
            </Box>
          <Box sx={{display: {sm: 'none', xs:'block' }, width:'10%' }}/>
          <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
            {navItems.map((item) => (
              <Button size='large' href={item.url} key={item.name} sx={{ color: '#87877E', marginLeft: '1rem', fontSize:'1rem' }}>
                {item.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ marginLeft:'auto', display: { xs: 'none', sm: 'block' }}}>
            {localStorage.getItem('user') !== null? 
              <>
              <Button onClick={handleClick} sx={{color:'#00B8DF'}} >
                <Avatar sx={{backgroundColor:'#00B8DF'}}></Avatar>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box sx={{padding:'0.5rem', backgroundColor:'#fffff'}}>
                  <Button size='large' href='/#/panel'  sx={{color:'#00B8DF', marginBottom:'0.5rem'}} > <GradeIcon sx={{marginRight:'0.3rem'}} /> Mis Sorteos</Button>
                <Divider sx={{backgroundColor:'white'}} />
                  <Button size='large' onClick={Remove} color='error' sx={{marginTop:'0.5'}}> <LogoutIcon sx={{marginRight:'0.3rem'}} /> Cerrar Sesión</Button>
                </Box>
              </Popover>
              </>
              :
              <Button sx={{color:'#00B8DF'}}  href='/#/userlogin' >Iniciar Sesión</Button>
            }
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    <Box sx={{ height:{sm:'2.5rem', xs:'2rem'}, marginTop:'2.8rem' }}/>
    </>
  );
}


export default Menu;