import { Button, Dialog, DialogContent, DialogTitle, Typography, DialogActions } from '@mui/material';
import * as React from 'react';

const DeleteModal = (props) =>{
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Button sx={{fontWeight:'bold'}} color='error' size="small" onClick={handleClickOpen}>
                Eliminar 
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontWeight:'600', width:'90%' }} id="customized-dialog-title">
                    Elimiar sorteo: {props.name}
                </DialogTitle>
                <DialogContent dividers>
                <Typography gutterBottom>¿Estás seguro que deseas eliminar el sorteo? Considere devoluciones y/o demás conflictos.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error'>
                        Eliminar
                    </Button>
                    <Button variant='outlined' color='error'>
                        Cancelar
                    </Button>
            </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteModal;