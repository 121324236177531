import FormContac from "./components/formulario_contacto"
import Social from "./components/redes"



const ContactoComponents = () =>{
    return(
        <>
        <FormContac/>
        <Social/>
        </>
    )
}

export default ContactoComponents;