import IMAGEN from '../../tablero_inicio/components/promo1.png';
import IMAGEN1 from '../../tablero_inicio/components/promo2.png';
import IMAGEN2 from '../../tablero_inicio/components/promo3.png';
import IMAGEN3 from '../../tablero_inicio/components/promo4.png';
import {Box, Grid, Card, CardActions, CardContent, CardMedia, Typography, Button} from '@mui/material';
import DialogInfo from './dialogInfo';
import DeleteModal from './deleteModal';


const data = [
    {
        id: 0,
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante. Disfruta tres días y dos noches en Cancún.',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2024-12-31T23:59:59'),
        numeros: 10000,
        promocion: 1
    }, 
    {
        id: 1,
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        description: 'Tienes la oportunidad de ganar un vuelo redondo a todo México. Increíble oportunidad de hacer el viaje que tanto quieres.',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2024-08-15T12:30:00'),
        numeros: 1000,
        promocion: 2
    }, 
    {
        id: 2,
        imgUrl: IMAGEN2,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2025-04-01T08:45:00'),
        numeros: 10000,
        promocion: 3
    },
    {
        id: 3,
        imgUrl: IMAGEN3,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2024-11-20T18:00:00'),
        numeros: 10000,
        promocion: 2
    },
    {
        id: 4,
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2025-07-10T05:15:30'),
        numeros: 1000,
        promocion: 1
    },
    {
        id: 5,
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        description: 'Tienes la oportunidad de ganar un vuelo redondo a todo México. Increíble oportunidad de hacer el viaje que tanto quieres.',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2024-02-05T21:10:00'),
        numeros: 10000,
        promocion: 3
    }
]

const Activos = () =>{
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return(
        <>
        <Box sx={{ width:'100%'}}>
            <Grid container spacing={3} >
                {
                    data.length > 0?
                    data.map((sorteo, index)=>(
                        <Grid key={'sorteoItem'+index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    alt={sorteo.imgAlt}
                                    height="140"
                                    image={sorteo.imgUrl}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'500'}}>
                                    {sorteo.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'#87877E'}}>
                                    {sorteo.description}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'black'}}>
                                    fecha: {new Intl.DateTimeFormat('es-ES', options).format(sorteo.date).toString()}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{paddingBottom:'1rem'}}>
                                    <Button href='/#/formsorteo' sx={{ fontWeight:'bold'}} color='success' size="small">Editar</Button>
                                    <DialogInfo name={sorteo.name} url={sorteo.imgUrl} urlAlt={sorteo.imgAlt} descripcion={sorteo.descripcionDetallada} numeros={sorteo.numeros} promocion={sorteo.promocion} />
                                    <DeleteModal name={sorteo.name} />
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                    :
                    <>No tiene sorteos activos</>
                }
            </Grid>
        </Box>
        </>
    )
}

export default Activos;