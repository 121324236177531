import {Box, Typography} from '@mui/material'
import IMAGE from '../../tablero_inicio/components/promo1.png'


const Banner = () => {
    return(
        <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden'}}>
            <div>
                <img
                src={IMAGE}
                alt='Banner Sorteo'
                style={{ width: '100%' }}
                />
            </div>
            <Box
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#00B8DF',
            padding: '25px',
            textAlign: 'center',
            borderRadius:'1rem'
            }}
            >
                <Typography variant="h5" sx={{ color: 'white', fontSize: { xs: '1.2rem', sm: '4rem' }, fontStyle:'italic', fontWeight:'600' }}>
                    Viaje a Cancún
                </Typography>
            </Box>
        </Box>
    )
}

export default Banner;