import PanelAdmin from "../../modules/tablero_admin";


function AdminComponent() {
    return(
        <>
        <PanelAdmin/>
        </>
    )
}

export default AdminComponent;