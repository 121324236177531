import ResumenOptions from "../../modules/resumen";




function Resumen() {
    
    return(
        <ResumenOptions/>
    )
}

export default Resumen;