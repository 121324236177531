import {Box, Grid, Typography, Divider, Container, Link} from '@mui/material';
import { Facebook, Instagram } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const DataRedes = [
    {
        name: 'Facebook',
        url: 'https://www.facebook.com/neoworktravel',
        logo: <Facebook sx={{fontSize:'6rem', color:'#00B8DF'}} />, 
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/neoworktravel',
        logo: <Instagram sx={{fontSize:'6rem', color:'#00B8DF'}} />,
    },
    {
        name: 'Whatsapp',
        url: 'https://wa.me/+5219613367173',
        logo: <WhatsAppIcon sx={{fontSize:'6rem', color:'#00B8DF'}} />, 
    }
]

const Social = () => {
    return(
        <>
        <Box sx={{marginTop:'2rem', marginBottom:'3rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Visita nuestras <span style={{color:'#00B8DF'}}>redes sociales</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginTop:'2rem'}}>
                <Container>
                    <Box sx={{paddingLeft:'1rem', paddingRight:'1rem'}}>
                        <Grid container spacing={2}>
                            {
                                DataRedes.map((item, index)=>(
                                    <>
                                    <Grid item key={'social'+index} md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box key={'box_social'+index}>
                                            <Link href={item.url} component={'a'} target='_blank'>
                                                {item.logo}
                                            </Link>
                                            <Typography gutterBottom sx={{textAlign:'center', fontWeight:'550'}}>{item.name}</Typography>
                                        </Box>
                                    </Grid>
                                    </>
                                ))
                            }
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
        </Box>
        </>
    )
}

export default Social;