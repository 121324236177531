import FormSorteo from "../../modules/formSorteo";



function SorteoForm() {
    return(
        <>
        <FormSorteo/>
        </>
    )
}

export default SorteoForm;