import ContainerPanel from "./components/containerPanel";


const UserPanelComponent = () =>{
    return(
        <>
        <ContainerPanel/>
        </>
    )
}

export default UserPanelComponent;