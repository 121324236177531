import Banner from "./components/banner";
import ContainerProceso from "./components/container_proceso";
import Descripcion from "./components/sorteo_descript";



const SorteoOptions = () => {
    return(
        <>
        <Banner/>
        <Descripcion/>
        <ContainerProceso/>
        </>
    )
}

export default SorteoOptions;