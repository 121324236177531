import AyudaOptions from "../../modules/tablero_ayuda";



function Ayuda() {
    return(
        <AyudaOptions/>
    )
}

export default Ayuda;