import Preguntas from "./components/preguntasrespuesta";
import PDFViewer from "./components/terminos";


const AyudaOptions = ()=>{
    return(
        <>
        <Preguntas/>
        <PDFViewer/>
        </>
    )
}

export default AyudaOptions;