import {Box, Grid, Link, Typography} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';

const AdminMenu = () => {
    return(
        <>
        <Box>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                    <Link component={'a'} href='/#/formsorteo'>
                        <Box sx={{cursor:'pointer', borderRadius:'2rem', borderColor:'#00B8DF', borderWidth: '1px', borderStyle:'solid', padding:'1rem', width:'80%', textDecoration:'none'}}>
                            <Typography gutterBottom sx={{color:'black', textAlign:'center', fontSize:'1rem', fontWeight:'550'}}>
                                <AddCircleIcon sx={{marginRight:'0.5rem'}}/>
                                Crear nuevo sorteo
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <Link component={'a'} href='/#/contrasenia'>
                        <Box sx={{cursor:'pointer', borderRadius:'2rem', borderColor:'#87877E', borderWidth: '1px', borderStyle:'solid', padding:'1rem', width:'80%', textDecoration:'none'}}>
                            <Typography gutterBottom sx={{color:'black', textAlign:'center', fontSize:'1rem', fontWeight:'550'}}>
                                <EnhancedEncryptionIcon sx={{marginRight:'0.5rem'}}/>
                                Cambiar contraseña
                            </Typography>
                        </Box>
                    </Link>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default AdminMenu;