import TableroInicio from "../../modules/tablero_inicio";


function Home() {
    return(
        <>
        <TableroInicio/>
        </>
    )
}

export default Home; 