import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import Answer from './dialog_respuesta';

const Data = [
    {
        question: "¿Cómo puedo participar en los sorteos?",
        answer: [
            'Para participar es muy sencillo. Simplemente entra en la sección de Sorteos y da clic en el botón INGRESAR de la rifa que más te atraiga. No hay límite de rifas en las que puedes participar',
            'Posteriormente elige tus boletos, ya sea con las sugerencias que da el sistema o medianrte el formulario. Si no tienes una cuenta, te pedirá que te registres antes de avanzar o en su defecto que inicies sesión.', 
            'Una vez seleccionados, seguirá el proceso de pago. Una vez realizado el pago, quedas registrado en el sorteo con los números correspondientes y se te notificará mediante tu correo tu proceso.'
        ]
    }, 
    {
        question: "¿Tengo que ser miembro de Neowork?",
        answer: [
            'No es necesario ser socio de Neowork para participar en los sorteos. Estas rifas son libres para todo público y no hay preferencias en el momento de elegir un ganador.',
            'Aunque si eres socio Neowork, tendrás la oportunidad de adquirir los boletos de los sorteos a precios y promociones especiales, los cuales alguien que no está afiliado no podrá.', 
        ]
    }, 
    {
        question: "¿Cuál es el proceso de elegir al ganador?",
        answer: [
            'Todos los sorteos tienen una fecha establecida al final de los formularios, así como una cuenta regresiva que avisa el tiempo que le queda al sorteo.',
            'Las fechas establecidas hacen alusión a fechas establecidas por la Lotería Nacional, por lo que los ganadores anunciados en vivo o posteriormente de dicho día, son los ganadores del sorteo.',
            'Este método se estableció con el fin de cumplir los lineamientos y no permitir que terceros se involucren en las decisiones de los ganadores. Una vez anunciados los ganadores, se les notificará.'
        ]
    }, 
    {
        question: "¿Puedo invitar a más gente a participar?",
        answer: [
            'Claro, los sorteos son abiertos a todo público. Si quieres que más gente conozca y tenga la oportunidad de ganar cualquiera de nuestros increíbles premios. Simplemente da clic en el ícono de compartir que viene al lado del botón de INGRESAR. Se habilitarán opciones de compartir el link del sorteo, elige el que mejor se adapte a tus necesidades.',
        ]
    }
]


const Preguntas = () =>{
    return(
        <Box>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Preguntas frecuentes de nuestro <span style={{color:'#00B8DF'}}>sorteo</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginTop:'4rem'}}>
            <Container>
                <Box sx={{ width:'100%', display:'flex', justifyContent:'center', flexDirection:'column'}}>
                    {
                        Data.map((dialog, index)=>(
                            <Box key={'qa'+index} sx={{width:{sm:'100%', xs:'90%'}, backgroundColor:'#ffffff', boxShadow: '0px 10px 10px -2px rgba(0, 0, 0, 0.1)', padding:'1rem', marginBottom:'1rem'}} >
                                <Grid container spacing={3}>
                                    <Grid item sm={8} xs={12} sx={{display:{xs:'flex'}, justifyContent:{xs:'center', sm:'left'}}}>
                                        <Typography sx={{fontWeight:'550'}} >{dialog.question}</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12} sx={{display:{xs:'flex'}, justifyContent:{xs:'center'}}}>
                                        <Answer  question={dialog.question} answer={dialog.answer} />
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                    }
                </Box>
            </Container>
            </Box>
        </Box>   
    )
}

export default Preguntas;