import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import IMAGEN from '../../tablero_inicio/components/promo1.png';
import IMAGEN1 from '../../tablero_inicio/components/promo2.png';
import IMAGEN2 from '../../tablero_inicio/components/promo3.png';

const data = [
    {
        id: 0,
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        numeros: ['0230', '2344', '9912'],
        ganador: '9912'
    }, 
    {
        id: 1,
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        numeros: ['0012'],
        ganador: '1289'
    }, 
    {
        id: 2,
        imgUrl: IMAGEN2,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        numeros: ['9012', '0921', '4392'],
        ganador: '1289'
    }
]

const CardsFinalizados = () =>{
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return(
        <>
        <Box sx={{ width:'100%'}}>
            <Grid container spacing={3} >
                {
                    data.length > 0?
                    data.map((sorteo, index)=>(
                        <Grid key={'sorteoItem'+index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    alt={sorteo.imgAlt}
                                    height="140"
                                    image={sorteo.imgUrl}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'500'}}>
                                    {sorteo.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'#87877E'}}>
                                    Números: {sorteo.numeros.map((num)=>(<>&nbsp;|{num}|&nbsp;</>))}
                                    <br/>
                                    {sorteo.numeros.filter(num=>num==sorteo.ganador) > 0? <span style={{color:'green'}}>GANASTE: </span> : <span style={{color:'red'}}>PERDISTE: </span>} 
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'black'}}>
                                    Número Ganador: &nbsp;|{sorteo.ganador}|&nbsp;
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{paddingBottom:'1rem'}}>
                                    <Button href='/#/resumen' sx={{ fontWeight:'bold'}} color='error'  size="small">Ir al resumen</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                    :
                    <>No estás participando en ningún sorteo actualmente.</>
                }
            </Grid>
        </Box>
        </>
    )
}

export default CardsFinalizados;