import Box from '@mui/material/Box';
import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import IMAGEN from './slide.jpeg';
import IMAGEN1 from './slide2.jpeg';
import VIDEO1 from './video1.mp4';
import VIDEO2 from './video2.mp4';

const data = [
  { id: 1, type: 'image', imgUrl:IMAGEN},
  { id: 3, type: 'video', imgUrl:VIDEO1 },
  { id: 2, type: 'image', imgUrl:IMAGEN1 },
  { id: 5, type: 'video', imgUrl:VIDEO2 },
];

const SliderImages = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
    <Box sx={{ width: '100%'}}>
      <Slider {...settings}>
      {data.map((step) => (
        <div key={step.id}>
            {
              step.type === 'image'? 
              <img
              src={step.imgUrl}
              alt={step.id}
              style={{ width: '100%' }}
              />
              :
              <video
              autoPlay
              muted
              loop
              src={step.imgUrl}
              alt={step.id}
              style={{ width: '100%' }}
              />
            }
        </div>
      ))}
    </Slider>
    </Box>
    </>
  );
}


export default SliderImages;