import ContainerAdmin from "./components/containerPanel"


const PanelAdmin = () =>{
    return(
        <>
        <ContainerAdmin/>
        </>
    )
}

export default PanelAdmin;