import { Box, Divider, Accordion, AccordionDetails, AccordionSummary, Typography, Container} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WidgetsIcon from '@mui/icons-material/Widgets';
import GradeIcon from '@mui/icons-material/Grade';
import AdminMenu from './adminMenu';
import TabsSorteos from './panelSorteos';

const ContainerAdmin = () =>{
    return(
        <Box sx={{marginTop:'2rem', marginBottom:'3rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Container>
                <Box  sx={{marginTop:'2rem'}}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color:'#00B8DF'}} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <WidgetsIcon sx={{marginRight:'0.5rem', color:'#00B8DF'}}/>
                        <Typography gutterBottom sx={{color:'#00B8DF', fontWeight:'600', fontSize:'1rem'}}>Administrar sorteos y usuario</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AdminMenu/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color:'#00B8DF'}} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <GradeIcon sx={{marginRight:'0.5rem', color:'#00B8DF'}}/>
                        <Typography gutterBottom sx={{color:'#00B8DF', fontWeight:'600', fontSize:'1rem'}}>Tablero de Sorteos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TabsSorteos/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Container>
        </Box>
    )
}

export default ContainerAdmin;