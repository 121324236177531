import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

import TERMINOS from './terminos.pdf';

const PDFViewer = () => {

  return (
    <>
      <Box sx={{ marginTop: '2rem', marginBottom: '3rem' }}>
        <Divider variant="middle" sx={{ backgroundColor: '#00B8DF', height: '1px', marginTop: '2rem' }} />
        <Box sx={{ marginTop: '2rem' }}>
          <Grid container spacing={3}>
            <Grid item sm={1} xs={1}></Grid>
            <Grid item sm={4} xs={8}>
              <Typography variant='h4' sx={{ textTransform: 'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight: '600' }}>
                Términos y condiciones: <a href={TERMINOS} download style={{ color: '#00B8DF', cursor: 'pointer' }}>descargar</a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PDFViewer;
