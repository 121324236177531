import UserRegisterComponent from "../../modules/registro";


function Register() {
    return(
        <>
        <UserRegisterComponent/>
        </>
    )
}

export default Register;