import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const FinalizarModal = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return(
        <>
        <Button sx={{ fontWeight:'bold'}} color='success' size="small" onClick={handleClickOpen}>
            Finalizar
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                handleClose();
            },
            }}
        >
            <DialogTitle>{props.name}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Escribe el número ganador según lo presenciado en la Lotería Nacional.
            </DialogContentText>
            <TextField
                autoFocus
                required
                margin="dense"
                id="number"
                name="number"
                label="Número"
                type="number"
                variant="standard"
                fullWidth
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="number2"
                name="number2"
                label="Confirmar número"
                type="number"
                variant="standard"
                fullWidth
            />
            </DialogContent>
            <DialogActions>
            <Button variant='outlined' color='error' onClick={handleClose}>Cancelar</Button>
            <Button variant='contained' color='success' type="submit">Confirmar</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default FinalizarModal;