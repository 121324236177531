import {Box, Divider, Grid, Typography, Container, Card, CardMedia, CardContent, CardActions, Button} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import IMAGEN from './promo1.png';
import IMAGEN1 from './promo2.png';
import IMAGEN2 from './promo3.png';
import IMAGEN3 from './promo4.png';


const data = [
    {
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante. Disfruta tres días y dos noches en Cancún.'
    }, 
    {
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        description: 'Tienes la oportunidad de ganar un vuelo redondo a todo México. Increíble oportunidad de hacer el viaje que tanto quieres.'
    }, 
    {
        imgUrl: IMAGEN2,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún'
    },
    {
        imgUrl: IMAGEN3,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún'
    },
    {
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún'
    },
    {
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        description: 'Tienes la oportunidad de ganar un vuelo redondo a todo México. Increíble oportunidad de hacer el viaje que tanto quieres.'
    }
]

const Sorteos = ()=>{
    return(
        <Box id='sorteos' sx={{marginTop:'2rem', marginBottom:'3rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Elige el mejor <span style={{color:'#00B8DF'}}>sorteo</span>  para tí
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{marginTop:'2rem'}}>
            <Container>
                <Box sx={{ width:'100%'}}>
                    <Grid container spacing={3} >
                        {
                            data.map((sorteo, index)=>(
                                <Grid key={'sorteoItem'+index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            component="img"
                                            alt={sorteo.imgAlt}
                                            height="140"
                                            image={sorteo.imgUrl}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'500'}}>
                                            {sorteo.name}
                                            </Typography>
                                            <Typography variant="body2" sx={{color:'#87877E'}}>
                                            {sorteo.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions sx={{paddingBottom:'1rem'}}>
                                            <Button href='/#/sorteo' sx={{color:'#00B8DF', fontWeight:'bold'}} size="small">Ingresar</Button>
                                            <Button sx={{color:'#00B8DF', fontWeight:'bold'}} size="small">
                                                <ShareIcon/>
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
            </Box>
        </Box>
    )
}

export default Sorteos;