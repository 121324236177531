import {Box, Avatar, Typography, TextField, Button, RadioGroup, Radio, FormControlLabel, FormControl,FormLabel } from '@mui/material'
import GradeIcon from '@mui/icons-material/Grade';


const FormSorteo = () => {
    return(
        <>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding:'1rem'
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
                <GradeIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
            Nuevo Sorteo
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 1, width:'80%' }}>
                <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Título"
                type="text"
                id="name"
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="descripcion"
                label="Descripcion breve"
                type="text"
                id="descripcion"
                rows={3}
                multiline
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="descripcion2"
                label="Descripcion amplia"
                type="text"
                id="descripcion2"
                rows={6}
                multiline
                />
                <TextField
                margin="normal"
                required
                fullWidth
                name="fecha"
                type="date"
                id="fecha"
                />
                <TextField
                sx={{marginBottom:'1rem', marginTop:'1rem'}}
                label="Seleccionar imagen"
                type="file"
                required
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                />
                <FormControl sx={{width:'50%'}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Números</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="1,000" control={<Radio />} label="1,000" />
                        <FormControlLabel value="10,000" control={<Radio />} label="10,000" />
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{width:'50%'}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Promoción</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="2x1" control={<Radio />} label="2x1" />
                        <FormControlLabel value="3x1" control={<Radio />} label="3x1" />
                        <FormControlLabel value="3x1" control={<Radio />} label="4x1" />
                    </RadioGroup>
                </FormControl>
                <Button
                    href='/#/admin'
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                        backgroundColor: '#0494b3'
                    },}}
                >
                    Agregar
                </Button>
            </Box>
        </Box>
        </>
    )
}

export default FormSorteo;