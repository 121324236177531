import { Box, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import IMAGEN from '../../tablero_inicio/components/promo1.png';
import IMAGEN1 from '../../tablero_inicio/components/promo2.png';
import IMAGEN3 from '../../tablero_inicio/components/promo4.png';
import DialogInfo from './dialogInfo';
import FinalizarModal from './finalizarModal';


const data = [
    {
        id: 0,
        imgUrl: IMAGEN1,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante. Disfruta tres días y dos noches en Cancún.',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2020-12-31T23:59:59'),
        numeros: 10000,
        promocion: 1
    }, 
    {
        id: 1,
        imgUrl: IMAGEN3,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        description: 'Tienes la oportunidad de ganar un vuelo redondo a todo México. Increíble oportunidad de hacer el viaje que tanto quieres.',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2023-08-15T12:30:00'),
        numeros: 1000,
        promocion: 2
    }, 
    {
        id: 2,
        imgUrl: IMAGEN,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2021-04-01T08:45:00'),
        numeros: 10000,
        promocion: 3
    },
    {
        id: 3,
        imgUrl: IMAGEN,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        description: 'Obtén el viaje de tus sueños todo pagado para tí y un acompañante 3 días y 2 noches a Cancún',
        descripcionDetallada: ['Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, officiis! Aut veritatis dolorum incidunt corporis provident facere voluptatem voluptatibus, tempore libero ut beatae, magni enim consectetur adipisci vel, nam nulla.'],
        date: new Date('2022-11-20T18:00:00'),
        numeros: 10000,
        promocion: 2
    }
]

const Finalizar = () =>{
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return(
        <>
        <Box sx={{ width:'100%'}}>
            <Grid container spacing={3} >
                {
                    data.length > 0?
                    data.map((sorteo, index)=>(
                        <Grid key={'sorteoItem'+index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    alt={sorteo.imgAlt}
                                    height="140"
                                    image={sorteo.imgUrl}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'500'}}>
                                    {sorteo.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'#87877E'}}>
                                    {sorteo.description}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'black'}}>
                                    Expiró el: {new Intl.DateTimeFormat('es-ES', options).format(sorteo.date).toString()}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{paddingBottom:'1rem'}}>
                                    <FinalizarModal name={sorteo.name} />
                                    <DialogInfo name={sorteo.name} url={sorteo.imgUrl} urlAlt={sorteo.imgAlt} descripcion={sorteo.descripcionDetallada} numeros={sorteo.numeros} promocion={sorteo.promocion} />
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                    :
                    <>No tiene sorteos por finalizar</>
                }
            </Grid>
        </Box>
        </>
    )
}

export default Finalizar;