import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import * as React from 'react';


const DialogInfo = (props) =>{
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Button sx={{color:'#00B8DF', fontWeight:'bold'}} size="small" onClick={handleClickOpen}>
                Ver info
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontWeight:'600', width:'90%' }} id="customized-dialog-title">
                    {props.name}
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                <img style={{width:'100%'}} src={props.url} alt={props.urlAlt} />
                <Typography gutterBottom>Descripción completa: </Typography>
                {
                    props.descripcion.map((p, index)=>(
                        <Typography key={'p'+index} gutterBottom>
                            {p}
                        </Typography>
                    ))
                }
                <Typography gutterBottom>Números disponibles: {props.numeros} </Typography>
                <Typography gutterBottom>Promoción: {props.promocion === 1? <>2x1</>: props.promocion===2? <>3x1</>: <>4x1</> } </Typography>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DialogInfo;