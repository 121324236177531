import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import IMAGE from '../tablero_inicio/components/promo1.png';


const LoginComponent = () => {
    return(
        <>
        <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden'}}>
            <div>
                <img
                src={IMAGE}
                alt='Banner Sorteo'
                style={{ height: '100%' }}
                />
            </div>
            <Box
            sx={{
            width: {sm:'70%', md:'auto', xs:'70%'},
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            borderRadius:'1rem', 
            backgroundColor:'white', 
            padding:'2rem'
            }}
            >
                <>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Iniciar Sesión
                        </Typography>
                        <Box component="form" noValidate  sx={{ mt: 1 }}>
                            <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="user"
                            label="Usuario"
                            type="user"
                            id="user"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                        />
                        <Button
                            href='/#/admin'
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                                backgroundColor: '#0494b3'
                            },}}
                        >
                            Iniciar
                        </Button>
                        </Box>
                    </Box>
                    </>
            </Box>
        </Box>
        </>
    )
}

export default LoginComponent;