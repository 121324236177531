import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import { Autocomplete, Avatar, Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import ModalSocio from './modalSocio';
import CountdownTimer from './countdown';

const ContainerProceso = () =>{

    const [aleatorios, setAleatorios] = useState([])
    const [numeros, setNumeros] = useState([])
    const [seleccionados, setSeleccionados] = useState([])

    function desordenarLista(lista) {
        for (let i = lista.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [lista[i], lista[j]] = [lista[j], lista[i]];
        }
        return lista;
    }

    function NumsAleatorios(boletos) {
        var listaAleatoria = desordenarLista([...boletos].filter((numero)=>numero.estado===true));
        var lista = []
        for (let i = 0; i < 4; i++) {
            var aux = []
            for (let j = 0; j < 6; j++) {
                aux.push(listaAleatoria.pop())
            }
            lista.push(aux)
        }

        setAleatorios(lista)
    }

    useEffect(()=>{
        const boletos = []
        for (let index = 0; index < 10000; index++) {
            const cadena = '0000';
            const nuevoNumero = cadena + index
            const nuevaCadena = nuevoNumero.slice(-4);
            if (index % 3 !== 0) {
                boletos.push({
                    id: index,
                    label: nuevaCadena,
                    estado: true
                })
            }else{
                boletos.push({
                    id: index,
                    label: nuevaCadena,
                    estado: false
                })
            }
        }
        setNumeros(boletos)
        console.log(numeros)

        NumsAleatorios(boletos)
    }, [])

    const handleClickAdd = (num) => {
        setSeleccionados((prevArray) => [...prevArray, num]);
    };

    const handleClickSubtract = (num) =>{
        const nuevoArray = seleccionados.filter((item) => item.id !== num.id);
        setSeleccionados(nuevoArray);
    }

    
    return(
        <>
        <Box sx={{marginTop:'2rem'}}>
            <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
            <Box  sx={{marginTop:'2rem'}}>
                <Grid container spacing={3}>
                    <Grid item sm={1} xs={1}></Grid>
                    <Grid item sm={4} xs={8}>
                        <Typography variant='h4' sx={{textTransform:'uppercase', fontSize: { xs: '1.2rem', sm: '2rem' }, fontWeight:'600'}}>
                            Selecciona tus <span style={{color:'#00B8DF'}}>boletos</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Container>
                <Box>
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item md={8} sm={6} xs={12}>
                            <Box
                                sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection:'column'
                                }}
                            >
                                <Grid container spacing={2}>
                                {
                                    aleatorios.map((item, index)=>(
                                        <Grid container item key={index} spacing={2}  justifyContent="center">
                                            {
                                                item.map((num, jindex)=>(
                                                    <Grid item key={jindex} md={2} sm={6} xs={4}  sx={{display:'flex', justifyContent:"center"}}>
                                                            {
                                                                seleccionados.filter((selec)=>selec.id === num.id).length > 0?
                                                            <Button 
                                                            variant={'contained'} 
                                                            onClick={()=>handleClickSubtract(num)}
                                                            style={{ height: '80px', width:'85px', overflow: 'hidden', whiteSpace: 'nowrap', backgroundColor:'#00B8DF' }} >
                                                                {num.label}
                                                            </Button> :
                                                            <Button 
                                                            variant={'outlined'} 
                                                            onClick={()=>handleClickAdd(num)}
                                                            style={{ height: '80px', width:'85px', overflow: 'hidden', whiteSpace: 'nowrap', borderColor:'#00B8DF', color:'#00B8DF'}} >
                                                                {num.label}
                                                            </Button>
                                                            }
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    ))
                                }  
                                </Grid>
                                <Box sx={{marginTop:'2rem'}}>
                                    <Button sx={{color:"#87877E"}} onClick={()=>NumsAleatorios(numeros)}>
                                        Ver más números
                                        <RefreshIcon/>
                                    </Button>
                                </Box>
                                <Box sx={{marginTop:'1rem'}}>
                                    <Typography gutterBottom>
                                        Boletos disponibles: <span style={{color:'#00B8DF'}} >{numeros.filter((numero)=>numero.estado === true).length}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                        <>
                            <Box
                                sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: '#00B8DF', padding:'3px' }}>
                                    <EmojiTransportationIcon/>
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                Registro de Participación
                                </Typography>
                                <Box component="form" noValidate  sx={{ mt: 1 }}>
                                {
                                    localStorage.getItem('user') === null? 
                                    <Typography sx={{textAlign:'center', color:'red'}}>
                                        Es necesario haberse registrado, o en su defecto iniciado sesión, para poder participar en el sorteo.
                                    </Typography>
                                    :
                                    <></>
                                }
                                <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        sx={{marginTop:'1rem'}}
                                        options={ numeros.filter((numero)=>numero.estado===true).sort((a, b) => a.id - b.id)}
                                        value={seleccionados}
                                        onChange={(event, newValue) => {
                                            setSeleccionados([
                                              ...newValue,
                                            ]);
                                        }}
                                        getOptionLabel={(option)=>option.label}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Boletos"
                                            placeholder="Boletos"
                                            required
                                        />
                                        )}
                                    />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, backgroundColor:'#00B8DF', '&:hover': {
                                        backgroundColor: '#0494b3'
                                    },}}
                                >
                                    Registrar Participación
                                </Button>
                                <ModalSocio promocion={'3x1'} />
                                <CountdownTimer targetDate={new Date('2024-12-31T23:59:59')} />
                                </Box>
                            </Box>
                            </>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
        <Divider variant="middle" sx={{backgroundColor:'#00B8DF', height:'1px', marginTop:'2rem'}} />
        </>
    )
}

export default ContainerProceso;