import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram } from "@mui/icons-material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from "@mui/material";
import LOGO from '../head_menu/logo-main.png';


function Footer(params) {
    return (
        <Box
          component="footer"
          sx={{
            marginTop:'5rem',
            backgroundColor:'#fffff',
            color:'#00B8DF',
            boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',  
            border: '3px solid #ccc',  
            paddingTop: '2rem'
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={4}>
              <div key='1'>
                  <Link href='/'>
                  <img
                    src={LOGO}
                    alt='Logo letra Neowork'
                    style={{ width: '100%', cursor:'pointer' }}
                  />
                  </Link>
              </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6"  gutterBottom sx={{fontWeight: '600'}}>
                  Contacto
                </Typography>
                <Typography variant="body2" sx={{fontWeight: '600'}}>
                  Correo: reservaciones.neoworktravel@gmail.com
                </Typography>
                <Typography variant="body2" sx={{fontWeight: '600'}}>
                  Telefono: <a href="tel:9613367173" style={{color:'#00B8DF'}}>(961) 336-7173</a>
                </Typography>
                <Link component='a' target="_blank" href="https://wa.me/+5219613367173"  color="inherit">
                  <WhatsAppIcon fontSize="large" sx={{marginTop:'0.5rem'}} />
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6"  gutterBottom sx={{fontWeight: '600'}}>
                  Síguenos
                </Typography>
                <Link component='a' target="_blank" href="https://www.facebook.com/neoworktravel" color="inherit">
                  <Facebook />
                </Link>
                <Link
                  component='a' target="_blank"
                  href="https://www.instagram.com/neoworktravel"
                  color="inherit"
                  sx={{ pl: 1, pr: 1 }}
                >
                  <Instagram />
                </Link>
                <Link component='a' target="_blank" href="https://wa.me/+5219613367173"  color="inherit">
                  <WhatsAppIcon />
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2"  align="center" sx={{fontWeight: '600'}}>
                {"Copyright © "}
                <Link color="inherit" component='a' target="_blank" href="https://neoworkglobal.com/">
                  neoworkglobal.com
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
                &nbsp; <a href="/#/login" target="_blank" style={{color:'#00B8DF', cursor:'pointer'}} >Administrador</a>
              </Typography>
            </Box>
          </Container>
        </Box>
      );
}

export default Footer;