import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import IMAGEN from '../../tablero_inicio/components/promo1.png';
import IMAGEN1 from '../../tablero_inicio/components/promo2.png';
import IMAGEN2 from '../../tablero_inicio/components/promo3.png';

const data = [
    {
        id: 0,
        imgUrl: IMAGEN,
        imgAlt: 'Viaje Cancun',
        name: 'Viaje a Cancún',
        date: new Date('2024-12-31T23:59:59'),
        numeros: ['0230', '2344', '9912']
    }, 
    {
        id: 1,
        imgUrl: IMAGEN1,
        imgAlt: 'Vuelo Redondo',
        name: 'Vuelo Redondo',
        date: new Date('2024-08-15T12:30:00'),
        numeros: ['0012']
    }, 
    {
        id: 2,
        imgUrl: IMAGEN2,
        imgAlt: 'Hotel Resort',
        name: 'Hotel Resort',
        date: new Date('2025-04-01T08:45:00'),
        numeros: ['9012', '0921', '4392']
    }
]

const CardsActivos = () =>{
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return(
        <>
        <Box sx={{ width:'100%'}}>
            <Grid container spacing={3} >
                {
                    data.length > 0?
                    data.map((sorteo, index)=>(
                        <Grid key={'sorteoItem'+index} item md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    alt={sorteo.imgAlt}
                                    height="140"
                                    image={sorteo.imgUrl}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'500'}}>
                                    {sorteo.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'#87877E'}}>
                                    Números: {sorteo.numeros.map((num)=>(<>&nbsp;|{num}|&nbsp;</>))}
                                    </Typography>
                                    <Typography variant="body2" sx={{color:'black'}}>
                                    fecha: {new Intl.DateTimeFormat('es-ES', options).format(sorteo.date).toString()}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{paddingBottom:'1rem'}}>
                                    <Button href='/#/sorteo' sx={{ fontWeight:'bold', color:'#00B8DF'}}  size="small">Ir al Sorteo</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                    :
                    <>No estás participando en ningún sorteo actualmente.</>
                }
            </Grid>
        </Box>
        </>
    )
}

export default CardsActivos;